import Explore from "./Explore"

function Main () {
    

    return (
        <div className="w-3/4 mt-20 max-w-screen-sm animator flex flex-col items-center justify-center">
            <div className="z-0 drop-shadow-sm opacity-90">
                <img src="https://misc-jax-0123.s3.us-east-2.amazonaws.com/banner.jpg" alt="Valerie Southern | Houston, TX Skyline" className="rounded-full pointer-events-none" draggable="false"/>
            </div>
            <div className="mt-4 w-full">
                <div className="gap-0 p-0 m-0">
                    <svg viewBox="0 0 1200 300" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" version="1.1">
                        <path 
                            d="M0 229L40 215.2C80 201.3 160 173.7 240 148.3C320 123 400 100 480 113.7C560 127.3 640 177.7 720 207.7C800 237.7 880 247.3 960 255.5C1040 263.7 1120 270.3 1160 273.7L1200 277L1200 301L1160 301C1120 301 1040 301 960 301C880 301 800 301 720 301C640 301 560 301 480 301C400 301 320 301 240 301C160 301 80 301 40 301L0 301Z"                        
                            fill="#607274" 
                            stroke-linecap="round" 
                            stroke-linejoin="round"
                            >
                        </path>
                    </svg>
                </div>
                <div className="gap-0 p-0 -mt-1 -mb-1 bg-[linear-gradient(to_bottom,_#607274_0%,_#607274b3_50%,_#607274_100%)]">
                    <Explore />
                </div>
                <div className="gap-0 p-0 m-0">
                    <svg className="transform rotate-180" viewBox="0 0 1200 300" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" version="1.1">
                        <path 
                            d="M0 229L40 215.2C80 201.3 160 173.7 240 148.3C320 123 400 100 480 113.7C560 127.3 640 177.7 720 207.7C800 237.7 880 247.3 960 255.5C1040 263.7 1120 270.3 1160 273.7L1200 277L1200 301L1160 301C1120 301 1040 301 960 301C880 301 800 301 720 301C640 301 560 301 480 301C400 301 320 301 240 301C160 301 80 301 40 301L0 301Z"                        
                            fill="#607274" 
                            stroke-linecap="round" 
                            stroke-linejoin="round"
                        >
                        </path>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Main