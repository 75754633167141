
function Footer () {
    const user_email = process.env.REACT_APP_EMAIL

    return (
        <div className="flex flex-col items-center justify-center pb-5 mt-20 mb-24">
            <div>
                <a href="https://www.instagram.com/val.southern/" target="_blank" rel="noopener noreferrer" className="hover:text-[#7f9699] hover:drop-shadow-md">
                    <i className="bi bi-instagram pr-4 pl-4 text-5xl"></i>
                </a>
                <a href={`mailto:${user_email}`} className="hover:text-[#7f9699] hover:drop-shadow-md">
                    <i className="bi bi-send pr-4 pl-4 text-5xl"></i>
                </a>
            </div>
            <div className="h-24 mt-6 mb-5 p-8 hover:drop-shadow-md hover:translate-y-0.5">
                <img src="https://misc-jax-0123.s3.us-east-2.amazonaws.com/firm-logo.png" alt="firm-logo" className="h-full"></img>
            </div>
        </div>
    )
}

export default Footer