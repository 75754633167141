import { useState } from "react"
import BuyForm from "../forms/BuyForm";
import LeaseForm from "../forms/LeaseForm";
import ListForm from "../forms/ListForm";

function Explore () {
    const [selectedForm, setSelectedForm] = useState(null);

    return (
        <div className="flex flex-col items-center w-full p-20">  
            {selectedForm === null && (
                <div className="animator flex flex-col items-center w-full">
                    <div className="flex flex-col items-center text-center text-white text-3xl select-none font-medium w-3/4">
                        <span className="text-nowrap">
                            READY TO START YOUR
                        </span>
                        <span className="text-nowrap">
                            NEXT REAL ESTATE
                        </span>
                        <span className="text-nowrap">
                            ADVENTURE?
                        </span>
                    </div>
                    <div className="mt-6">
                        <div 
                            className="flex flex-row justify-center p-5 m-8 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" 
                            onClick={() => setSelectedForm("buy")}
                        >
                            <i className="bi bi-houses mr-3"></i>
                            <p className="font-semibold select-none">BUY A HOME</p>
                        </div>
                        <div 
                            className="flex flex-row justify-center p-5 m-8 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" 
                            onClick={() => setSelectedForm("apartment")}
                        >
                            <i className="bi bi-building mr-3"></i>
                            <p className="font-semibold">LEASE AN APARTMENT</p>
                        </div>
                        <div 
                            className="flex flex-row justify-center p-5 m-8 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" 
                            onClick={() => setSelectedForm("sell")}
                        >
                            <i className="bi bi-key mr-3"></i>
                            <p className="font-semibold">SELL A PROPERTY</p>
                        </div>
                        <div 
                            className="flex flex-row justify-center p-5 m-8 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" 
                            onClick={() => setSelectedForm("rent")}
                        >
                            <i className="bi bi-house-lock mr-3"></i>
                            <p className="font-semibold select-none">RENT A HOME</p>
                        </div>
                    </div>
              
                </div>
            )}
            {selectedForm && (
                <div className="animator flex flex-col items-center w-full">
                    {selectedForm === "private" || selectedForm === "landlord" ? (
                        <div className="flex justify-center w-full mb-6">
                            <i className="bi bi-arrow-left-circle-fill text-2xl text-[#B2A59B] cursor-pointer text-5xl hover:text-[#5e5853] hover:translate-y-0.5 drop-shadow" 
                                onClick={() => setSelectedForm("rent")}></i>
                        </div>
                    ) : (
                        <div className="flex justify-center w-full mb-6">
                            <i className="bi bi-arrow-left-circle-fill text-2xl text-[#B2A59B] cursor-pointer text-5xl hover:text-[#5e5853] hover:translate-y-0.5 drop-shadow" 
                                onClick={() => setSelectedForm(null)}></i>
                        </div>
                    )}
                    {selectedForm === "buy" && <BuyForm />}
                    {selectedForm === "apartment" && <LeaseForm lease_type="apartment" />}
                    {selectedForm === "sell" && <ListForm listing_type="seller" />}
                    {selectedForm === "rent" && (
                        <div className="flex flex-col items-center">
                            <div>
                                <div 
                                    className="flex flex-row justify-center p-5 m-8 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" 
                                    onClick={() => setSelectedForm("landlord")}
                                >
                                    <i className="bi bi-signpost-2 mr-3"></i>
                                    <p className="font-semibold">I'M A LANDLORD</p>
                                </div>
                                <div 
                                    className="flex flex-row justify-center p-5 m-8 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" 
                                    onClick={() => setSelectedForm("private")}
                                >
                                    <i className="bi bi-search mr-3"></i>
                                    <p className="font-semibold">I'M A TENANT</p>
                                </div>
                            </div>
                    
                        </div>
                    )}
                    {selectedForm === "landlord" && <ListForm listing_type="landlord" />}
                    {selectedForm === "private" && <LeaseForm lease_type="private" />}
                </div>
            )}
        </div>
    );
}

export default Explore